import styled from 'styled-components'
import { Form, Button } from 'react-bootstrap'

export const PageTitle = styled.h1`
    font-size: 1.9rem;
    font-family: ${({theme}) => theme.fonts.font2};
`
export const TrackForm =  styled(Form)`
    font-family: ${({theme}) => theme.fonts.font2};
    background: ${({theme}) => theme.cardBg};
    padding: 1rem;
    border-radius: 10px;

    li{
        margin-bottom: 1rem;
    }
`
export const TrackInput = styled(TrackForm.Control)`
    background: ${({theme}) => theme.inputBg.elev3};
    color: #ffffff;
    border: 0;
    border-radius: 10px;

    &:focus-within{
        background: ${({theme}) => theme.inputBg.elev3};
        color: #ffffff;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover, 
    &:-webkit-autofill:focus, 
    &:-webkit-autofill:active{
        -webkit-box-shadow: 0 0 0 100px ${({theme}) => theme.inputBg.elev3} inset !important;
        border: 0;
        -webkit-text-fill-color: yellow !important;
        transition: background-color 5000s ease-in-out 0s;
    }
`
export const SectionTitle = styled.h2`
    font-size: 1.2rem;
    font-family: ${({theme}) => theme.fonts.font2};
`

export const SectionCol = styled.div`
    background: ${({theme}) => theme.cardBg};
    font-family: ${({theme}) => theme.fonts.font2};
    border-radius: 15px;
    padding: 1rem;
`

export const SectionCol2 = styled(SectionCol)`
    height: 60vh;
    overflow: scroll;
    overflow-x: hidden;
`

export const InfoTitle = styled.span`
    font-size: 0.8rem;
`

export const InfoDet = styled.span`
    font-size: 1rem;
    font-weight: 600;
`

export const InfoDetSmall = styled.span`
    font-size: 0.8rem;
`

export const Submit = styled(Button)`
    background: ${({theme}) => theme.accent.primary.color};
    color: ${({theme}) => theme.accent.primary.text};
    border: 0;
    font-weight: 700;
    width: 100%;

    &:hover{
        background: ${({theme}) => theme.accent.primary.color};
        color: ${({theme}) => theme.accent.primary.text};
        filter: brightness(90%);
    }
`