import React from 'react'
import { useLazyQuery, gql } from "@apollo/client";
import { Container, Row, Col, FloatingLabel, Badge } from 'react-bootstrap'
import { useForm } from "react-hook-form";
import parse from "url-parse"
// import {getCourierData} from '../components/getCourierData'
import { PageTitle, TrackForm, TrackInput, Submit, 
    SectionTitle, SectionCol,SectionCol2, InfoTitle, InfoDet, InfoDetSmall } from '../styles/pages/track.styles'

import { Icon } from '@iconify/react';
// import dot05XL from '@iconify-icons/ci/dot-05-xl';

const SRgetTrackData = gql`
query Shiprocket($id: ID!, $method: String!) {
    SRgetTrackData(id: $id, method: $method) {
        track_status
        shipment_status
        shipment_track_activities {
            location
            activity
            date
        }
        shipment_track {
            courier_agent_details
            origin
            consignee_name
            destination
            delivered_to
            current_status
            packages
            weight
            delivered_date
            pickup_date
            order_id
            shipment_id
            courier_company_id
            awb_code
            id
        }
    }
}
`

const NBgetTrackData = gql`
query Nimbuspost($nbawb: ID!){
    NBgetTrackData(awb: $nbawb) {
        status
        courier_id
        history {
        status_code
        location
        event_time
        message
        }
        warehouse_id
        order_id
    }
}
`;

const ShipmentHealth = (code) => {
    const health = code === 1 || 2 || 3 || 4 || 5 || 6 || 18 || 19 || 38 || 42 ? "primary" : code === 7 ? "success" : "warning"
    return health
}
  

const Track = () => {
    const { register, handleSubmit } = useForm();
    const onSubmit = (value) => {
        // setTrackId(value.trackform && value.trackform);
        NB = undefined
        SR = undefined
        value.trackform !== '' ? ShopifyData({variables: {orderno: value.trackform}}) : console.info("awb or orderno not present")
    }
  
    const [ShopifyData, {data}] = useLazyQuery(shopifyData, {fetchPolicy: "network-only"});
    let [NBgetData, NB] = useLazyQuery(NBgetTrackData, {fetchPolicy: "network-only"});
    let [ShiprocketData, SR] = useLazyQuery(SRgetTrackData, {fetchPolicy: "network-only"});

        const FinancialStatus = data?.getOrderDetails.displayFinancialStatus
        const trackInfo = data?.getOrderDetails?.fulfillments[0].trackingInfo[0]
        const money = data?.getOrderDetails.originalTotalPriceSet.presentmentMoney
        const gateway = data?.getOrderDetails.paymentGatewayNames[0]

        React.useEffect(() => {
            const {hostname} = parse(trackInfo?.url)
            if(hostname ==='rayhan.ordr.live'){
                NBgetData({variables: {nbawb: trackInfo.number}})
            } else if(hostname === 'rayhan.shiprocket.co'){
                ShiprocketData({variables: {id: trackInfo.number, method: 'awb'}})
            }
           
        },[trackInfo, NBgetData, ShiprocketData])

    return(
        <Container fluid className="px-4 mt-4">
            <Row>
                <Col sm={12} lg={4}>
                    <Row>
                        <Col sm={12}>
                            <PageTitle>Track your order</PageTitle>
                            <TrackForm onSubmit={handleSubmit(onSubmit)}>
                                <FloatingLabel controlId="orderInput" label="Type Your Order No. (#20200000)" className="mb-3">
                                    <TrackInput type="text" name="orderno" placeholder="Type Your Order No. (#20200000)" {...register("trackform")}  />
                                </FloatingLabel>
                                <Submit type="submit" >Submit</Submit>
                            </TrackForm>
                        </Col>
                        <Col sm={12} className="mt-5">
                            <SectionCol>
                                <SectionTitle>Things to note</SectionTitle>
                                <ul>
                                    <li>We can't cancel after the courier pickup is done.<br/>Please ping us before the dispatch via whatsapp or telegram.</li>
                                    <li>For south india, most of the package reach you in 3 working days. for north india it takes 5 to 6 days</li>
                                    <li>Usuall courier pickup Cutoff time is every day 10:00AM, So orders created after 10AM will be shipped on next schedule.</li>
                                    <li>There is no pickup available on sundays. so the orders created after 10AM on Saturday will be shipped on monday.</li>
                                    <li>Pickup will be delayed on National Holidays, Regional Festiv Holidays.</li>
                                </ul>
                            </SectionCol>
                            
                        </Col>
                    </Row>
                </Col>
                <Col sm={12} lg={8} className="mt-5 mt-md-0">
                    
                    <Row className="mb-3">
                        <Col>
                            <SectionTitle>Order Details</SectionTitle>
                            <SectionCol className="">
                            { data && 
                                <Row sm={2} md={4}>
                                    <Col className="d-flex flex-column my-1 my-md-0">
                                        <InfoTitle>Order No.</InfoTitle>
                                        <InfoDet>{data.getOrderDetails.name}</InfoDet>
                                        <InfoDetSmall>{data.getOrderDetails.createdAt}</InfoDetSmall>
                                        <InfoDetSmall>{data.getOrderDetails.confirmed === "true" ? "Confirmed" : "Not Yet Confirmed"}</InfoDetSmall>
                                    </Col>
                                    <Col className="d-flex flex-column my-1 my-md-0">
                                        <InfoTitle>Order Type</InfoTitle>
                                        <InfoDet>{gateway === "cash_on_delivery" ? "Cash On Delivery" : "PREPAID"}</InfoDet>
                                        {gateway !== "cash_on_delivery" && <InfoDetSmall>Via: {gateway}</InfoDetSmall> }
                                    </Col>
                                    <Col className="d-flex flex-column my-1 my-md-0">
                                        <InfoTitle>Payment Amount / Status</InfoTitle>
                                        <InfoDet className="me-2">{money.amount}<Badge bg="dark">{FinancialStatus === 'PENDING' ? "COD" : <><Icon icon="bi:check2-circle" inline={true} className="me-2" />PAID</>}</Badge></InfoDet>
                                        <InfoDetSmall></InfoDetSmall>
                                    </Col>
                                    <Col className="d-flex flex-column my-1 my-md-0">
                                        <InfoTitle>Shipment Details</InfoTitle>
                                        {trackInfo.company && <InfoDet>{trackInfo.company}</InfoDet> }
                                        <InfoDetSmall>AWB: {trackInfo.number}</InfoDetSmall>
                                        <InfoDetSmall><a href={trackInfo.url} target="_blank" rel="noreferrer"><Icon icon="bi:link-45deg" inline={true} /> Partner's Tracking page</a></InfoDetSmall>
                                    </Col>
                                </Row>
                            }
                            {SR?.data &&
                                <Row sm={2} md={4} className="mt-3">
                                    <Col className="d-flex flex-column">
                                        <InfoTitle>From</InfoTitle>
                                        <InfoDet>{SR.data?.SRgetTrackData.shipment_track[0].origin}</InfoDet>
                                        <InfoDetSmall>{SR.data?.SRgetTrackData.shipment_track[0].pickup_date}</InfoDetSmall>
                                    </Col>
                                    <Col className="d-flex flex-column">
                                        <InfoTitle>To</InfoTitle>
                                        <InfoDet>{SR.data?.SRgetTrackData.shipment_track[0].destination}</InfoDet>
                                        <InfoDetSmall>{SR.data?.SRgetTrackData.shipment_track[0].delivered_date && SR.data.SRgetTrackData.shipment_track[0].delivered_date}</InfoDetSmall>
                                    </Col>
                                    <Col className="d-flex flex-column">
                                        <InfoTitle>Status</InfoTitle>
                                        <InfoDet className="d-flex flex-column">
                                            <Badge bg={ShipmentHealth(SR.data.SRgetTrackData.shipment_status)}>{SR.data.SRgetTrackData.shipment_status}</Badge>
                                            {SR.data.SRgetTrackData.shipment_track[0].current_status}
                                        </InfoDet>
                                    </Col>
                                    <Col className="d-flex flex-column">
                                        <InfoTitle>Weight &amp; Package</InfoTitle>
                                        <InfoDet className="d-flex flex-column">{SR.data.SRgetTrackData.shipment_track[0].weight} Kg</InfoDet>
                                        <InfoDetSmall>{SR.data.SRgetTrackData.shipment_track[0].packages} Pack</InfoDetSmall>
                                    </Col>
                                </Row>
                            }
                            {NB?.data && 
                                <Row className="mt-3">   
                                    <Col className="d-flex flex-column">
                                        <InfoTitle>Status</InfoTitle>
                                        <InfoDet className="d-flex flex-column">
                                            {NB.data.NBgetTrackData.status}
                                        </InfoDet>
                                    </Col>
                                </Row>
                            }
                            </SectionCol>
                        </Col>
                    </Row>
                   
                    {NB?.data && 
                    <Row>
                        <Col>
                            <SectionTitle>Location History</SectionTitle>
                            <SectionCol2 className="d-flex flex-column">
                                {
                                    NB.data.NBgetTrackData.history.map((item, index) => {
                                        let dateData = new Date(item.event_time)
                                        return(
                                            <div key={index} className="d-flex flex-row align-items-center">
                                                <div className="me-4">
                                                    <InfoDetSmall className="d-flex flex-column">
                                                        <span>{`${dateData.getDate()}-${dateData.getMonth()}-${dateData.getFullYear()}`}</span>
                                                        <span>{`${dateData.getHours()}:${dateData.getMinutes()}:${dateData.getSeconds()}`}</span>
                                                    </InfoDetSmall>
                                                </div>
                                                <div key={index} className="d-flex flex-column my-2">
                                                    <InfoDet>{item.status_code} - {item.message}</InfoDet>
                                                    <InfoTitle>Location: {item.location}</InfoTitle>
                                                    
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </SectionCol2>
                        </Col>
                    </Row>}
                    {SR?.data &&
                    <Row>
                        <Col>
                            <SectionTitle>Location History</SectionTitle>
                            <SectionCol2 className="d-flex flex-column">
                                {
                                    SR.data.SRgetTrackData.shipment_track_activities.map((item, index) => {
                                        let dateData = new Date(item.date)
                                        return (
                                            <div key={index} className="d-flex flex-row align-items-center">
                                                <div className="me-4">
                                                    <InfoDetSmall className="d-flex flex-column">
                                                        <span>{`${dateData.getDate()}-${dateData.getMonth()}-${dateData.getFullYear()}`}</span>
                                                        <span>{`${dateData.getHours()}:${dateData.getMinutes()}:${dateData.getSeconds()}`}</span>
                                                    </InfoDetSmall>
                                                </div>
                                                <div key={index} className="d-flex flex-column my-2">
                                                    <InfoDet> {item.activity}</InfoDet>
                                                    <InfoTitle>Location: {item.location}</InfoTitle>
                                                    
                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </SectionCol2>
                        </Col>
                    </Row>
                    }
                </Col>
            </Row>
        </Container>
    )
} 

const shopifyData = gql`
query shopifyData($orderno: ID!) {
    getOrderDetails(orderno: $orderno) {
        id
        phone
        createdAt
        paymentGatewayNames
        tags
        note
        displayFulfillmentStatus
        displayFinancialStatus
        confirmed
        name
        originalTotalPriceSet {
            presentmentMoney {
                amount
                currencyCode
            }
        }
        fulfillments {
            displayStatus
            trackingInfo {
                company
                number
                url
            }
        }
    }
}
`;

export default Track